<template>
  <div class="">
    <div class="bg-white mx-auto mt-8 max-w-5xl md:shadow" v-if="model">
      <div
        class="grid grid-rows-4 lg:grid-rows-1 lg:grid-cols-4 gap-4 p-4 border-t first:border-t-0 lg:border-t-0"
        v-for="(recipient, index) in model.recipients"
        :key="recipient.id"
      >
        <sqr-input-text
          label="Société"
          :value="recipient.company"
          @change="recipientFieldSet(index, 'company', $event)"
        />
        <sqr-input-text
          label="Nom"
          :value="recipient.name"
          @change="recipientFieldSet(index, 'name', $event)"
        />
        <sqr-input-email
          label="email"
          :value="recipient.email"
          @change="recipientFieldSet(index, 'email', $event)"
        />
        <div class="mt-6">
          <sqr-button
            icon="trash"
            label="Supprimer"
            color="white"
            @click="remove(index)"
          />
        </div>
      </div>
      <div class="grid grid-cols-4 gap-4 mt-4 p-4 bg-gray-50">
        <sqr-input-text label="Société" v-model="entry.company" />
        <sqr-input-text label="Nom" v-model="entry.name" />
        <sqr-input-text label="email" v-model="entry.email" />
        <div class="mt-6">
          <sqr-button
            icon="plus"
            label="Ajouter"
            color="primary"
            @click="add()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { clone, assocPath } from 'ramda';
import SqrButton from '@/sqrd-ui/SqrButton';
import SqrInputText from '@/sqrd-ui/SqrInputText';
import SqrInputEmail from '@/sqrd-ui/SqrInputEmail';
import nanoRef from '@/utils/nanoRef';

export default {
  name: 'InquiryModelRecipients',
  components: { SqrButton, SqrInputText, SqrInputEmail },
  computed: {
    ...mapState('inquiryModel', { model: 'doc' }),
    ...mapState('inquiryModel', ['path'])
  },
  data() {
    return { entry: { company: '', name: '', email: '' } };
  },
  methods: {
    ...mapActions('updater', ['update']),
    fieldSet(field, value) {
      const path = this.path;
      return this.update({ path, doc: { [field]: value } });
    },
    recipientFieldSet(index, field, value) {
      const from = this.model?.recipients ?? [];
      const recipients = assocPath([index, field], value, from);
      // const id = 'r' + nanoRef(5);
      // const recipients2 = assocPath([index, 'id'], id, recipients);
      this.fieldSet('recipients', recipients);
    },
    add() {
      let recipients = clone(this.model.recipients ?? []);
      const id = 'r' + nanoRef(5);
      const entry = {...this.entry, id};
      recipients.push(entry);
      this.entry = { company: '', name: '', email: '' };
      this.fieldSet('recipients', recipients);
    },
    remove(index) {
      let recipients = clone(this.model.recipients);
      recipients.splice(index, 1);
      this.fieldSet('recipients', recipients);
    }
  },
  metaInfo() {
    const name = this.model?.name ?? '';
    const title = `${name} - destinataires - modèle de demande d'offres`;
    return { title };
  },
};
</script>
